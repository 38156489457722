<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :md="8" :sm="12">
                <el-form-item label="操作类型">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.businessType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(b, index) in businessTypes"
                      :key="index"
                      :value="b.dictValue"
                      :label="b.dictLabel"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="操作状态">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.status"
                    style="width: 100%"
                  >
                    <el-option :value="0" label="成功"></el-option>
                    <el-option :value="1" label="失败"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="15">
                <el-form-item label="操作人员">
                  <el-input
                    clearable
                    placeholder="请输入"
                    v-model="form.operName"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="18">
                <el-form-item label="操作时间">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.range"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="操作模块">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.title"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in moduleList"
                      :key="index"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="operId"
          align="center"
          label="日志编号"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="title"
          align="center"
          label="系统模块"
          width="110"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="96"
          prop="businessType"
          label="操作类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.businessType | operTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operName"
          min-width="120"
          label="操作人员"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="operIp"
          min-width="140"
          label="主机"
        >
        </el-table-column>
        <el-table-column
          align="center"
          min-width="100"
          prop="operLocation"
          show-overflow-tooltip
          label="操作地点"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="operUrl"
          min-width="180"
          label="请求地址"
        >
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operTime"
          width="200"
          sortable="custom"
          label="操作时间"
        >
        </el-table-column>
        <!-- <el-table-column
          align="center"
          width="120px"
					:label="$t('option')">
          v-if="buttonAuth.includes('monitor:operlog:detail')"
          <template slot-scope="scope">
            <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)">详情</el-button>
          </template>
				</el-table-column> -->
      </el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('monitor:operlog:remove')"
          @click="cleanList"
          >清空日志</el-button
        >
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('monitor:operlog:export')"
          @click="exportExcel(apis.operLog_export, {})"
          >导出</el-button
        >
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import download from "@/mixins/download";
import { getDictArray } from "@/unit/dict";
import UserSearchClass from "./userSearchClass";

const operTypeMap = {};
export default {
  mixins: [mixin, download],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      businessTypes: [],
      moduleList: [],
    };
  },
  computed: {
    ...mapState(["Language"]),
  },
  filters: {
    operTypeFilter(type) {
      return operTypeMap[type].text;
    },
    statusFilter(status) {
      const statusMap = {
        1: "失败",
        0: "成功",
      };
      return statusMap[status];
    },
  },
  async created() {
    // 字典两种用法，各有优缺点
    // operTypeMap = await getDictMap('sys_oper_type')
    // this.operTypeMap = operTypeMap
    // this.operTypeMap.forEach((value, key, mymap) => {
    //   this.businessTypes.push({ code: key, label: value })
    // })
    const businessTypes = await getDictArray.call(this, "sys_oper_type");
    this.businessTypes = businessTypes.data;
    this.businessTypes.forEach((d) => {
      operTypeMap[d.dictValue] = { text: d.dictLabel };
    });
  },
  activated() {
    this.getOprationModule();
  },
  methods: {
    /**
     * 获取操作模块数据
     */
    getOprationModule() {
      console.log(123);
      this.$http.get(apis.operLog_modulesList).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          this.moduleList = res.data.data;
          console.log(this.moduleList);
        }
      });
      console.log(this.paymentMethod);
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      const formData = {
        ...this.form,
      };
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.beginTime = this.form.range[0];
          formData.endTime = this.form.range[1];
        }
      } else {
        formData.beginTime = "";
        formData.endTime = "";
      }
      this.$http
        .get(apis.operLog_List, {
          params: formData,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 清空列表
     */
    cleanList() {
      this.$http.post(apis.operLog_clean).then((res) => {
        if (res.data.code === 0) {
          this.searchEvent();
        }
      });
    },
    /**
     * @function 导出
     */
    exportList() {
      this.$http
        .post(apis.operLog_export, {}, { params: this.form })
        .then((res) => {
          if (res.data.code === 0) {
            this.searchEvent();
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.table-container {
  margin-top: 0;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
