import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.operName = ''
      this.businessType = ''
      this.status = ''
      this.range = []
      this.sortField = 'operTime'
      this.sortOrder = 'desc'
      this.title = ''
    }
  }
}
export default searchFrom
